import { Button, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FaCheckCircle } from "react-icons/fa";
import { IoCloseCircleOutline } from "react-icons/io5";
import { Navigate } from "react-router-dom";
import { fruitColors, globalStyles, inputStyle, margins } from "../../colors/global-styles";
import { ColoredBorderBottomTopComponent } from "../../components/colored-box.component";
import { FlexComponent } from "../../components/flex.component";
import { ProgressComponent, Steps } from '../../components/progress-bar.component';
import { OneRowComponent } from "../../components/rows/one-row.component";
import { Lang } from '../../lang/lang'; // Import Lang for translations
import { UserListItem } from "../../ov-common/models/user";
import { mapUserToUserListItem } from "../../ov-common/utilities/map.utilities";
import { getSteve } from "../../services/users.sevice";
import { AvatarMapperComponent } from "../avatar/avatar.component";
import { getUser, useCheckValidCode, useCheckValidUsername, useRegister } from "./hook/auth-query";

export const MAX_AGE = 6;

export const SignupComponent = () => {
    const { t } = Lang(); // Access translation function
    const currentDate = new Date();
    const maxDate = new Date();
    maxDate.setFullYear(currentDate.getFullYear() - MAX_AGE);
    maxDate.setMonth(11);
    maxDate.setDate(31);
    const maxDateStr = maxDate.toISOString().substr(0, 10);
    const isAuth = getUser();
    const [ username, setUsername ] = useState<string>('');
    const [ password, setPassword ] = useState<string>('');
    const [ dateOfBirth, setDateOfBirth ] = useState<string>('');
    const [ steve, setSteve ] = useState<UserListItem | null>(null);
    const { mutate: register } = useRegister();
    const [ code, setCode ] = useState<string>('');
    const { mutateAsync: checkValidCode } = useCheckValidCode();
    const [ validCode, setValidCode ] = useState<boolean>(false);
    const [ hasCheckedCode, setHasCheckedCode ] = useState<boolean>(false);
    const { mutateAsync: checkValidUsername } = useCheckValidUsername();
    const [ validUsername, setValidUsername ] = useState<boolean>(false);
    const toaster = useToast();

    const handleSignUp = () => {
        register({ anvandarnamn: username, losenord: password, fodelsedatum: new Date(dateOfBirth), langIndex: 'English', code: code });
    };

    const handleDateSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        if (new Date(value).getTime() > new Date(maxDateStr).getTime()) {
            setDateOfBirth(maxDateStr);
        } else {
            setDateOfBirth(value);
        }
    };

    useEffect(() => {
        getSteve().then((st) => {
            if (st) {
                setSteve(mapUserToUserListItem(st));
            }
        });
    }, []);

    const steps = new Steps(5, 1);

    useEffect(() => {
        if(code.length >= 7 || hasCheckedCode && code.length > 3){
            setHasCheckedCode(true);
            checkValidCode({
                code: code,
            }).then(() => {
                setValidCode(true);
            }).catch(() => {
                setValidCode(false);
            });
        }
    }, [ code ]);

    useEffect(() => {
        if(username.length >= 4){
            checkValidUsername(username).then(() => {
                setValidUsername(true);
            }).catch(() => {
                setValidUsername(false);
            });
        }
    }, [ username ]);

    return (
        <div className="background-important" style={{ paddingBottom: 50, textAlign: "center" }}>
            {isAuth ? <Navigate to='/edit-avatar' state={{ from: 'signup' }} /> : null}
            <ProgressComponent steps={steps.steps} atStep={steps.atStep} />
            <Container>
                <Row>
                    <Col>
                        {steve && <FlexComponent>
                            <AvatarMapperComponent width={100} avatar={steve.avatar} />
                        </FlexComponent>}
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col>
                        <FlexComponent>
                            <div style={margins.margin}>
                                <h1><span>{t("signupFillIn")} </span><span>{t("signupYour")} </span><span>{t("signupLoginDetails")}</span></h1>
                            </div>
                        </FlexComponent>
                    </Col>
                </Row>
            </Container>
            <OneRowComponent>
                <form>
                    <ColoredBorderBottomTopComponent>
                        <OneRowComponent>
                            <label htmlFor="username">{t("signupUsernameLabel")}</label>
                        </OneRowComponent>
                        <OneRowComponent>
                            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                <div style={{ flex: 2, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                    {username.length > 3 && validUsername ? (
                                        <FaCheckCircle size={30} color={fruitColors.apple.color} />
                                    ) : (
                                        <IoCloseCircleOutline size={30} color={globalStyles.darkGrayColor.color} />
                                    )}
                                </div>
                                <div style={{ flex: 8 }}>
                                    <input
                                        type="text"
                                        name="username"
                                        id="username"
                                        style={inputStyle.input}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUsername(e.target.value)}
                                    />
                                </div>
                            </div>
                        </OneRowComponent>
                        {username.length > 3 && !validUsername ? (
                            <div style={{ color: globalStyles.redColor.color, marginTop: -20 }}>
                                {t("signupInvalidUsername")}
                            </div>
                        ) : null}

                        <OneRowComponent>
                            <label htmlFor="password">{t("signupPasswordLabel")}</label>
                        </OneRowComponent>
                        <OneRowComponent>
                            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                <div style={{ flex: 2, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                    {password.length > 3 ? (
                                        <FaCheckCircle size={30} color={fruitColors.apple.color} />
                                    ) : (
                                        <IoCloseCircleOutline size={30} color={globalStyles.darkGrayColor.color} />
                                    )}
                                </div>
                                <div style={{ flex: 8 }}>
                                    <input
                                        type="password"
                                        name="password"
                                        id="password"
                                        style={inputStyle.input}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                                    />
                                </div>
                            </div>
                        </OneRowComponent>

                        <OneRowComponent>
                            <label htmlFor="dateOfBirth">{t("signUpInvitationCode")}</label>
                        </OneRowComponent>
                        <OneRowComponent>
                            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                {hasCheckedCode ? 
                                    <div style={{ flex: 2, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                        {validCode ?
                                            <FaCheckCircle size={30} color={fruitColors.apple.color} />
                                            :
                                            <IoCloseCircleOutline size={30} color={globalStyles.redColor.color} />
                                        }
                                    </div>
                                    : null
                                }
                                <div style={{ flex: 8 }}>
                                    <input
                                        type="text"
                                        name="code"
                                        id="code"
                                        style={inputStyle.input}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCode(e.target.value)}
                                    />
                                </div>
                            </div>
                        </OneRowComponent>
                        <OneRowComponent>
                            <label htmlFor="dateOfBirth">{t("signupDateOfBirthLabel")}</label>
                        </OneRowComponent>
                        <OneRowComponent>
                            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                <div style={{ flex: 2, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                    {dateOfBirth.length > 3 ? (
                                        <FaCheckCircle size={30} color={fruitColors.apple.color} />
                                    ) : (
                                        <IoCloseCircleOutline size={30} color={globalStyles.darkGrayColor.color} />
                                    )}
                                </div>
                                <div style={{ flex: 8 }}>
                                    <input
                                        type="date"
                                        name="dateOfBirth"
                                        id="dateOfBirth"
                                        style={inputStyle.input}
                                        value={dateOfBirth}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleDateSelect(e)}
                                    />
                                </div>
                            </div>
                        </OneRowComponent>

                        <OneRowComponent>
                            {username.length > 3 && password.length > 3 && dateOfBirth.length > 3 ? (
                                <Button colorScheme="teal" onClick={handleSignUp} m={3}>
                                    {t("signupNextStep")}
                                </Button>
                            ) : (
                                <Button onClick={() => {
                                    if(!validUsername){
                                        toaster({
                                            title: t("signupInvalidUsername"),
                                            status: "error",
                                            duration: 5000,
                                            isClosable: true,
                                        });
                                    }
                                }} colorScheme="gray" as="a" m={3}>
                                    {t("signupFillRequirements")}
                                </Button>
                            )}
                        </OneRowComponent>
                    </ColoredBorderBottomTopComponent>
                </form>
            </OneRowComponent>
        </div>
    );
};
