export const svTranslations = {
    "grade-go-back": "Tillbaka",
    "grade-this-month": "Den här månaden",
    "grade-total-score": "Totalt antal poäng",
    "grade-max-score": "Maximalt antal poäng",
    "grade-average-grade": "Genomsnittligt antal rätt per brev",
    "grade-nothing-to-show": "Inget att visa",
    settingsTitle: "Inställningar",
    settingsLanguage: "Språk",
    settingsAge: "Ålder",
    settingsAvatar: "Avatar",
    settingsBio: "Ämne",
    settingsUsernamePassword: "Användarnamn & Lösenord",
    settingsViewProfile: "Visa Profil",
    settingsSystemLanguage: "Systemspråk",
    settingsSelectLanguage: "Välj Språk",
    settingsEnglishUS: "Engelska (US)",
    settingsSwedishSV: "Svenska (SV)",
    settingsClose: "Stäng",
    settingsGoal: "Mål",
    languageScreenCurrentWritingLanguage: "Du skriver för närvarande på:",
    languageScreenSelectLanguagePrompt: "Vilket språk vill du skriva brev på?",
    languageScreenSearchPlaceholder: "Sök och välj språk",
    ageScreenEnterBirthday: "Vänligen ange din födelsedag",
    ageScreenUpdateAgeButton: "Uppdatera ålder",
    avatarScreenTopType: "Huvudbonad och hår",
    avatarScreenAccessoriesType: "Tillbehör",
    avatarScreenHatColor: "Hattfärg",
    avatarScreenHairColor: "Hårfärg",
    avatarScreenFacialHairType: "Ansiktshår",
    avatarScreenFacialHairColor: "Ansiktshårfärg",
    avatarScreenClotheType: "Kläder",
    avatarScreenClotheColor: "Klädfärg",
    avatarScreenEyeType: "Ögon",
    avatarScreenEyebrowType: "Ögonbryn",
    avatarScreenMouthType: "Mun",
    avatarScreenSkinColor: "Hudfärg",
    avatarScreenBack: "Tillbaka",
    avatarScreenSave: "Spara",
    avatarScreenCannotSelect: "Kan inte välja:",
    "save": "Spara",
    "create": "Skapa",
    writeBioPrompt: "Vad vill du bli bättre på?",
    writeBioPlaceholder: "Skriv ett ämne",
    addAnotherCard: "Lägg till ett till ämne",
    writeBioSubmit: "Skicka in",
    writeBioSkip: "Hoppa över",
    profileScreenHeading: "Uppdatera kontoinformation",
    profileScreenUsernameLabel: "Användarnamn",
    profileScreenUsernamePlaceholder: "Ange nytt användarnamn",
    profileScreenPasswordLabel: "Lösenord",
    profileScreenPasswordPlaceholder: "Ange nytt lösenord",
    profileScreenUpdateButton: "Uppdatera profil",
    profileScreenUpdating: "Uppdaterar...",
    profileScreenUpdateSuccessTitle: "Profil uppdaterad",
    profileScreenUpdateSuccessDescription: "Ditt användarnamn och lösenord har uppdaterats.",
    profileScreenUpdateErrorTitle: "Fel",
    profileScreenUpdateErrorDescription: "Det gick inte att uppdatera din profil.",
    homeScreenNoNewMessages: "Brevbäraren är ute för leverans",
    homeScreenLogout: "Logga ut",
    homeScreenPostmanDelivery: "Brev levereras av Brevbäraren och tar cirka 4 timmar",
    homeScreenPostmanFromMe: "Ditt brev är på väg",
    homeScreenPostmanFromOther: "Brev är på väg. Förväntas anlända om 4 timmar",
    navBarSettings: "Inställningar",
    navBarGrade: "Betyg",
    sendLettersMessageErrorTitle: "Meddelandet måste innehålla minst 50 ord",
    sendLettersTo: "Till",
    sendLettersPlaceholder: "Skriv ditt brev...",
    sendLettersGrammarError: "Grammatikfel",
    sendLettersMe: "jag",
    sendLettersYou: "du",
    sendLettersNoMessages: "Inga meddelanden",
    sendLettersGrade: "Betyg",
    sendLettersComment: "Kommentar",
    sendLettersNoMistakes: "Inga fel hittades. Bra jobbat!",
    sendLettersNoComments: "Inget att kommentera.",
    back: "Tillbaka",
    sendLettersGrammarSpellingComment: "Kommentar om grammatik- och stavfel",
    sendLettersTotalGrammarErrors: "Totala grammatikfel",
    sendLettersTotalSpellingErrors: "Totala stavfel",
    sendLettersSmogIndex: "Smog-index",
    sendLettersReadabilityScore: "Läsbarhetsbetyg",
    sendLettersGrammarErrorsByTotalWords: "Grammatikfel per totalt antal ord",
    sendLettersSpellingErrorsByTotalWords: "Stavfel per totalt antal ord",
    sendLettersQuantitativeScore: "Kvantitativt betyg",
    sendLettersQualitativeScore: "Kvalitativt betyg",
    loginTagline: "Allt bra kommer från kärlek",
    loginUsernameLabel: "Användarnamn",
    loginPasswordLabel: "Lösenord",
    loginButton: "Logga in",
    loginSignupLink: "Registrera dig",
    signupFillIn: "Fyll i",
    signupYour: "dina",
    signupLoginDetails: "inloggningsuppgifter",
    signupUsernameLabel: "Användarnamn",
    signupPasswordLabel: "Lösenord",
    signupDateOfBirthLabel: "Födelsedatum",
    signupNextStep: "Nästa steg",
    signupFillRequirements: "Fyll i alla krav...",
    progressStep: "av",
    "grade-grade-title": "Återkoppling",
    "grade-question": "Fråga",
    "grade-grade": "Kommentar",
    "grade-score": "Poäng",
    "grade-noExamplesAvailable": "Ingen återkoppling tillgänglig",
    goalScreenTitle: "Ange ditt lärandemål",
    goalScreenDescription: "Välj ett fokusområde för din lärandeupplevelse:",
    goalScreenLanguageTitle: "Språk (nybörjare)",
    goalScreenLanguageDescription: "Fokusera på att lära dig nya språk genom att skriva brev. Breven från agenten är skrivna för nybörjare. Bedömningen kommer att fokusera på grammatik- och stavfel.",
    goalScreenKnowledgeTitle: "Kunskapsbaserade frågor (avancerat språkbruk)",
    goalScreenKnowledgeDescription: "Breven kommer att fokusera mer på kunskapsbaserade frågor på en nivå som passar din ålder. Bedömningen kommer att ge feedback på dina svar på frågorna. Språket kommer att vara mer avancerat.",
    goalScreenUpdateGoalButton: "Uppdatera mål",
    pass: "Godkänd",
    writeBioPromptLanguage: "Skriv något om dig själv",
    signUpInvitationCode: "Inbjudningskod",
    signupInvalidUsername: "Anvandarnamn upptaget",
    writeBioErrorTitle: "Lägg till ett ämne",
    writeBioErrorDescription: "Du måste lägga till ett ämne för att fortsätta.",
    signUpForFreeTrial: "Få 30 dagars gratis provperiod",
    signUpForFreeTrialDescription: "Testa applikationen gratis i 30 dagar. Bjud in andra helt gratis.",
    signUpForFreeTrialButton: "Sign up",
    signUpAlreadyHaveCode: "Har du redan en kod?",
    signUpInvalidCode: "Ogiltig kod",
    redeemCode: "Lös in kod",
    sendLettersMessageWaitForReply: "Vänta på svar tills du skickar ett nytt brev",
};