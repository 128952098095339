/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Flex, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, useDisclosure, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { BiPencil, BiRightArrow, BiUserCircle } from 'react-icons/bi';
import { FaCalendarAlt, FaCreditCard, FaFlag, FaGlobe, FaUser } from 'react-icons/fa';
import { GoGoal } from "react-icons/go";
import { NavLink, useLocation } from 'react-router-dom';
import MenuItems from '../../components/menu/menu-items';
import { MyProfileSection } from '../../components/menu/profile-section';
import { Lang } from '../../lang/lang';
import { getUser, useCheckValidCode, useUseCode } from '../auth/hook/auth-query';
import { RedeemCodeModal } from '../home-screen.screen';
import { GoBackButton } from '../letters/send-letters.screen';

export type sectionButtonType = {
	title: string;
	key?: string;
	subTitle?: string;
	onClick?: () => void;
	leftIcon?: any;
	rightElementText?: string;
	rightElement?: JSX.Element;
    to: string | null;
};

export const ProfileSections: sectionButtonType[] = [
    {
        title: "settingsBio",
        to: "/write-bio",
        leftIcon: <BiPencil />,
    },
    {
        title: "settingsLanguage",
        to: "/language",
        leftIcon: <FaGlobe />,
    },
    {
        title: "settingsAge",
        to: "/age",
        leftIcon: <FaCalendarAlt />,
    },
    {
        title: "settingsGoal",
        to: "/goals",
        leftIcon: <GoGoal />,
    },
    {
        title: "settingsAvatar",
        to: "/edit-avatar",
        leftIcon: <BiUserCircle />,
    },
    {
        title: "settingsUsernamePassword",
        to: "/profile",
        leftIcon: <FaUser />,
        subTitle: "settingsViewProfile",
    },
    {
        title: "settingsSystemLanguage",
        to: null,
        leftIcon: <FaFlag />,
    },
    {
        title: "redeemCode",
        to: null,
        leftIcon: <FaCreditCard />,
    },
];

export type INavButtonProps = {
	title: string;
	key?: string;
	subTitle?: string;
	titleColor?: string;
	onClick?: () => void;
	leftIcon?: any;
	rightElementText?: string;
	rightElement?: JSX.Element;
	to?: string;
};

export type INavButtonWithIconProps = Omit<INavButtonProps, "rightElement"> & {
	rightIcon?: any;
};

export const NavButtonWithIcon = ({ rightIcon, ...props }: INavButtonWithIconProps) => (
    <NavButton
        rightElement={
            <BiRightArrow />
        }
        {...props}
    />
);

export const NavButton = ({
    title,
    leftIcon,
    subTitle,
    rightElement,
    rightElementText,
    to,
    key,
    onClick,
}: INavButtonProps) => {
    return (
        <Stack
            key={key}
            onClick={onClick}
        >
            <NavLink to={to ?? ""} state={{ from: "settings" }}>
                <Flex align="center" justifyContent="space-between">
                    <Flex direction={"column"}>
                        <Flex align="center" gap={"0.5rem"}>
                            {leftIcon}
                            {title}
                        </Flex>
                        {subTitle}
                    </Flex>
                    <Flex
                        direction={"row"}
                        gap={"0.5rem"}
                        justifyContent={"center"}
                        alignItems={"center"}
                    >
                        {rightElementText}
                        {rightElement}
                    </Flex>
                </Flex>
            </NavLink>
        </Stack>
    );
};

export const SettingsScreen = () => {
    const { t, setLanguage } = Lang();  // Use translation function
    const location = useLocation();
    const [ clickedGoBack, setClickedGoBack ] = React.useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();

    const { mutateAsync: checkValidCode } = useCheckValidCode();
    const { mutateAsync: useCode } = useUseCode();
    const [ code, setCode ] = useState<string>("");
    const toaster = useToast();
    const [ modalOpen, setModalOpen ] = useState<boolean>(false);
    const user = getUser();
    const [ validCode, setValidCode ] = useState<boolean>(false);

    useEffect(() => {
        if(user){
            checkValidCode({ code: user.code, codeId: user.code }).then(() => {
                setValidCode(true);
            }).catch(() => {
                setValidCode(false);
            });
        }
    }, [ user ]);

    return (
        <>
            {RedeemCodeModal(modalOpen, setModalOpen, t, user, code, setCode, useCode, toaster, true)}
            {GoBackButton(clickedGoBack, location, setClickedGoBack, t)}
            <div style={{ padding: 20 }}>
                <MyProfileSection
                    title={t("settingsTitle")}
                    noPadding
                    headingProps={{ as: "h1", paddingBottom: "1rem" }}
                >
                    <MenuItems>
                        {ProfileSections
                            .filter(prof => prof.title === "redeemCode" ? !validCode : true)
                            ?.map((profileButton) => (
                                <NavButtonWithIcon
                                    key={profileButton.to + "Key"}
                                    onClick={() => {
                                        if(profileButton.title === "redeemCode") {
                                            setModalOpen(true);
                                        }else if (profileButton.title === "settingsSystemLanguage") {
                                            onOpen();
                                        }
                                    }}
                                    title={t(profileButton.title)}
                                    subTitle={profileButton.subTitle ? t(profileButton.subTitle) : undefined}
                                    leftIcon={profileButton.leftIcon}
                                    rightElementText={profileButton.rightElementText}
                                    to={profileButton.to ?? ""}
                                />
                            ))}
                    </MenuItems>
                </MyProfileSection>
            </div>

            {/* Language Modal */}
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{t("settingsSelectLanguage")}</ModalHeader>
                    <ModalBody>
                        <Button
                            onClick={() => {
                                setLanguage("en-US");
                                onClose();
                            }}
                            mb={3}
                            width="100%"
                        >
                            {t("settingsEnglishUS")}
                        </Button>
                        <Button
                            onClick={() => {
                                setLanguage("sv-SE");
                                onClose();
                            }}
                            width="100%"
                        >
                            {t("settingsSwedishSV")}
                        </Button>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="ghost" onClick={onClose}>{t("settingsClose")}</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};