import { sendPost, sendPostWithToken } from "../http-utilities/http-utilities";
import { User } from "../ov-common/models/user";

// Write a function that takes anvandarnamn, losenord, fodelsedatum and langIndex as parameters and use sendPost to send a post request to /anvandare/registreraNyAnvandare. return Promise of type AuthToken.

export const register = (anvandarnamn: string, losenord: string, fodelsedatum: Date, langIndex: string, code: string): Promise<User> => {
    return sendPost('anvandare/registreraNyAnvandare', { anvandarnamn, losenord, fodelsedatum, langIndex, code });
};

// Write a login function that takes anvandarnamn and losenord as parameters and use sendPost to send a post request to /anvandare/login. return Promise of type AuthToken.
export const login = (anvandarnamn: string, losenord: string): Promise<User> => {
    return sendPost('anvandare/login', { epost: anvandarnamn, losenord });
};

// Write a function that logout user by removing token from local storage.
export const logout = () => {
    localStorage.removeItem('token');
};

// write a function to anvandare/verify-token that takes in a token as parameter and returns a Promise any
export const verifyToken = (): Promise<User> => {
    return sendPostWithToken('anvandare/verify-token');
};

export const checkValidCode = (code?: string | null, codeId?: string | null): Promise<void> => {
    return sendPost('anvandare/check-valid-code', { code, codeId });
};

export const checkValidUsername = (anvandarnamn: string): Promise<void> => {
    return sendPost('anvandare/check-valid-username', { username: anvandarnamn });
};

export const useCode = (code: string): Promise<User> => {
    return sendPostWithToken('anvandare/use-code', { code });
};