/* eslint-disable @typescript-eslint/no-explicit-any */
// This component is used to send letters to other users, a user can write letters in a textarea and read previous letters

import { useEffect, useState } from 'react';
import { globalStyles } from '../../colors/global-styles';
import { ContainerFullWidthHeightComponent } from '../../components/rows/container-full-width-height.component';

import { FaArrowLeft, FaClipboard, FaComment, FaPaperPlane, FaSpellCheck } from 'react-icons/fa';
import { FlexComponent } from '../../components/flex.component';

import { CloseIcon, WarningIcon } from '@chakra-ui/icons';
import { Badge, Box, Button, Flex, Heading, IconButton, ModalBody, Spinner, Stack, TagLeftIcon, Text, useToast } from '@chakra-ui/react';
import React from 'react';
import { BiPencil } from 'react-icons/bi';
import { GoGoal } from 'react-icons/go';
import { Navigate, useLocation } from "react-router-dom";
import { formatDate, formatDateYYYYMMDD, isOlderThan4Hours } from '../../common/date.utilities';
import { GradeExplanationInfo } from '../../components/grade/grade-explanation';
import { Message } from '../../components/message';
import { Modal } from '../../components/modal/modal';
import { Lang } from '../../lang/lang';
import { Letter } from '../../ov-common/letter';
import { UserListItem } from '../../ov-common/models/user';
import { MetricsDTO } from '../../ov-common/text-analysis';
import { sortLettersByDate } from '../../ov-common/utilities/dates.utilities';
import { getUser } from '../auth/hook/auth-query';
import { AvatarMapperComponent } from '../avatar/avatar.component';
import { getPostman } from '../home-screen.screen';
import { useGetConversation } from './hooks/get-conversation-query';
import { useSendLetter, useSetLast } from './hooks/letter-query';

export const SendLettersComponent = () => {
    const location = useLocation();
    const myUser = getUser();
    const [ letterText, setLetterText ] = useState<string>('');
    const [ clickedGoBack, setClickedGoBack ] = useState<boolean>(false);
    const [ user, setUser ] = useState<UserListItem | null>(null);
    const toast = useToast();
    const [ hasTyped, setHasTyped ] = useState<boolean>(false);
    const [ modalOpen, setModalOpen ] = useState<boolean>(false);
    const [ metrics, setMetrics ] = useState<MetricsDTO | null | undefined>(null);
    const [ metricsDate, setMetricsDate ] = useState<string | null>(null);
    const { mutate: mutateSetLast } = useSetLast();
    const { data: letters, isError, isFetching } = useGetConversation(myUser?._id, location.state?.user?._id);
    const boxRef = React.createRef<HTMLDivElement>();
    const { mutate: sendLetter, isPending: letterSentLoading } = useSendLetter();
    const { t, lang } = Lang();
    const [ hasScrolledOnce, setHasScrolledOnce ] = useState<number>(0);

    useEffect(() => {
        if(letters && letters?.length > 0 && !isFetching && !isError){
            const allLettersIsOlderThan4Hours = letters.every((letter) => isOlderThan4Hours(letter.datum, (letters?.length ?? 0) <= 2));
            if(allLettersIsOlderThan4Hours){
                const allUnReadLetters = letters.filter((letter) => !letter.last);
                if (allUnReadLetters.length > 0) {
                    allUnReadLetters.forEach((letter) => {
                        mutateSetLast(letter._id);
                    });
                }
            }
        }
    }, [ letters ]);

    useEffect(() => {
        const user = location?.state?.user as UserListItem;
        if (user) {
            setUser(user);
        }
    }, [ location ]);

    useEffect(() => {
        if(boxRef && hasScrolledOnce <= 20 && !isFetching){
            boxRef.current?.scrollTo({
                top: boxRef.current?.scrollHeight,
            });
            setHasScrolledOnce(prev => prev + 1);
        }
    }, [ boxRef ]);

    const handleSendLetter = () => {
        // find the newest letter in letters
        const newestLetterInConversation = letters?.reduce((acc, letter) => {
            return new Date(acc.datum).getTime() > new Date(letter.datum).getTime() ? acc : letter;
        });
        if(newestLetterInConversation != null && (letters?.length ?? 0) >= 2 && newestLetterInConversation?.from._id === myUser?._id){
            toast({
                id: "error",
                title: t("sendLettersMessageWaitForReply"),
                isClosable: true,
                duration: 10000*2,
                status: "warning",
            });
            return;
        }
        if (!messageOk || user == null) {
            toast({
                id: "error",
                title: t("sendLettersMessageErrorTitle"),
                isClosable: true,
                duration: 10000*2,
                status: 'error',
            });
            return;
        }
        const lettersFromTo = letters?.filter((letter) => letter.from._id === user._id);
        if(lettersFromTo == null){
            return;
        }
        const sortedLetters = sortLettersByDate(lettersFromTo);
        const newestLetter = sortedLetters[sortedLetters.length - 1];
        // send letter to user
        sendLetter({ toText: user.anvandarnamn, to: user._id, message: letterText, repliedTo: newestLetter?._id });
        setLetterText('');
        clearSavedMessageFromLocalStorage();
        // scroll to bottom
        boxRef.current?.scrollTo({
            top: boxRef.current?.scrollHeight,
            behavior: "smooth",
        });
    };

    const saveMessageInLocalStorage = (message: string) => {
        localStorage.setItem('message' + letters?.[0]?.konversationID, message);
    };

    const getSavedMessageFromLocalStorage = () => {
        return localStorage.getItem('message' + letters?.[0]?.konversationID);
    };

    const clearSavedMessageFromLocalStorage = () => {
        localStorage.removeItem('message' + letters?.[0]?.konversationID);
    };

    useEffect(() => {
        const savedMessage = getSavedMessageFromLocalStorage();
        if (savedMessage && savedMessage.length > 0 && !hasTyped) {
            setLetterText(savedMessage);
        }else if(savedMessage == null && !hasTyped){
            setLetterText('');
        }
    }, [ letters ]);

    const groupLettersByDay = letters?.reduce((acc, letter) => {
        const date = new Date(letter.datum);
        const dateString = date.toDateString();
        if (!acc[dateString]) {
            acc[dateString] = [];
        }
        acc[dateString].push(letter);
        return acc;
    }, {} as { [dateString: string]: Letter[] }) ?? {};

    const array = Object.values(groupLettersByDay);
    // sort oldest to newest
    const sortedLetters = array.sort((a, b) => {
        return new Date(a[0].datum).getTime() - new Date(b[0].datum).getTime();
    });

    const wordCounter = (text: string) => {
        return text.split(/\s/).filter((word) => word.length > 0).length;
    };

    const messageOk = wordCounter(letterText) > 3;

    const getExplanationText = (value: string | number | undefined | null, message = true) => {
        return value != null ? value : message ? t("sendLettersNoMistakes") : t("sendLettersNoComments");
    };

    return (
        <ContainerFullWidthHeightComponent>
            {GoBackButton(clickedGoBack, location, setClickedGoBack, t)}
            <Flex
                direction={"column"}
                width="100%"
                position={"sticky"}
            >
                <Flex
                    alignItems="center"
                    ml={"1rem"}
                    pt={"1rem"}
                    borderBottom={"1px"}
                    borderBottomColor={"lightgray"}
                    pb={"0.5rem"}>
                    {user && <FlexComponent>
                        <AvatarMapperComponent
                            avatar={user.avatar}
                            width={50}
                            height={50}
                        />
                    </FlexComponent>}
                    <Box style={{ marginLeft: 50 }}>
                        {user != null && <Badge><span>{t("sendLettersTo")}: <strong>{user.anvandarnamn}</strong></span></Badge>}
                    </Box>
                </Flex>
            </Flex>
            <Box style={{ height: "calc(100vh - 230px)", overflowY: "scroll" }} ref={boxRef}>
                {isFetching && <Stack justifyContent={"center"} alignItems={"center"} style={{ height: "calc(100vh - 230px)" }}>
                    <Spinner size={"xl"} color={"teal"} />
                </Stack>}
                {!isFetching && <Flex direction="column" className="send-box" overflowY={"scroll"}>
                    <Box pl={"1rem"} pr={"1rem"}>
                        {user && groupLettersByDay != null && sortedLetters.length > 0 ? sortedLetters.map((messages) => {
                            const date = new Date(messages[0].datum);
                            const dateString = date.toDateString();
                            return (
                                <Box key={dateString}>
                                    <Flex alignItems={"center"} justifyContent={"center"}>
                                        <Text
                                            fontSize={"16px"}
                                            color={"black"}
                                            mt={"0.5rem"}>
                                            {formatDate(date)}
                                        </Text>
                                    </Flex>
                                    <Flex flexDirection={"column"} gap={"1rem"}>
                                        {messages
                                            .sort((a, b) => new Date(a.datum).getTime() - new Date(b.datum).getTime())
                                            .map((letter: Letter, index) => {
                                                const isTheFirstLetter = index === 0;
                                                const letterIs12HoursOld = isTheFirstLetter ? true : isOlderThan4Hours(letter.datum, (letters?.length ?? 0) <= 2);
                                                const sentByMe = letter.from._id === myUser?._id;
                                                const onOpen = () => {
                                                    if(!sentByMe) return;
                                                    setMetrics(letter.metrics);
                                                    setModalOpen(true);
                                                    setMetricsDate(formatDate(letter.datum, lang));
                                                };
                                                const score = letter?.metrics?.totalScore;
                                                const maxScore = letter?.metrics?.maxScore;
                                                const metrics = sentByMe && score != null ? (
                                                    <>
                                                        <p style={{ marginTop: 2 }} onClick={() => onOpen()}>
                                                            <span style={{ color: score != null && maxScore != null && score/maxScore >= 0.8  ? 'teal' : globalStyles.redColor.color }}>
                                                                <TagLeftIcon as={FaSpellCheck} />
                                                                {t("sendLettersGrade")}
                                                            </span>
                                                        </p>
                                                    </>
                                                ) : (
                                                    <Text>
                                                        {formatDateYYYYMMDD(letter.datum)}
                                                    </Text>
                                                );
                                                
                                                return (
                                                    <Box key={index} alignSelf={sentByMe ? "flex-end" : "flex-start"}>
                                                        
                                                        <Message
                                                            key={index}
                                                            bottomFooter={metrics}
                                                            author={sentByMe ? "me" : "you"}
                                                            autoFloat>
                                                            {!letterIs12HoursOld && !sentByMe ? getPostman(sentByMe, t) :
                                                                <p style={{ whiteSpace: 'pre-line' }}>{letter.meddelande}</p>}
                                                        </Message>
                                                    </Box>
                                                );
                                            })}
                                    </Flex>
                                </Box>
                            );
                        }) : <Text>{t("sendLettersNoMessages")}</Text>}
                    </Box>
                </Flex>}
            </Box>

            <div style={{ width: '100%', background: "#F6F5F4" }}>
                {!letterSentLoading ? <div style={{ justifyContent: "center", alignItems: "center", flexDirection: "row", display: "flex", height: "114px" }}>
                    <textarea 
                        value={letterText}
                        disabled={letters != null && (letters?.length ?? 0) > 2 && letters[letters.length - 1].from._id === myUser?._id}
                        style={{ background: "white", padding: 20 }} placeholder={t("sendLettersPlaceholder")} onChange={(e) => {
                            setLetterText(e.target.value);
                            setHasTyped(true);
                            saveMessageInLocalStorage(e.target.value);
                        }}></textarea>
                    {hasTyped && <Button 
                        size="md" 
                        onClick={() => handleSendLetter()}
                        disabled={!messageOk}
                        colorScheme={!messageOk ? globalStyles.redColor.color : 'teal'}
                        m={5}
                        p={0}
                    >
                        <FaPaperPlane size={20} color={!messageOk ? globalStyles.redColor.color : 'white'} />
                    </Button>}
                </div>
                    :
                    <Stack justifyContent={"center"} alignItems={"center"}>
                        <Spinner  size={"xl"} color={"teal"} />
                    </Stack>
                }
            </div>
            <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)} size='sm'>
                <ModalBody mb={"1rem"} overflow={"scroll"} p={"1rem"}>
                    <Stack justifyContent={"space-between"} direction="row" alignItems={"center"} mb={"1rem"}>
                        <Heading as="h1" fontSize={"2xl"} mb={"1rem"}>{t("sendLettersGrade")} ({metricsDate})</Heading>
                        <IconButton
                            aria-label="close"
                            icon={<CloseIcon />}
                            onClick={() => {
                                setModalOpen(false);
                                setMetrics(null);
                                setMetricsDate(null);
                            }}
                        />
                    </Stack>
                    <Flex justifyContent={"center"} mt={-10}>
                        {myUser?.avatar != null && <AvatarMapperComponent
                            width={80}
                            avatar={myUser?.avatar}
                        />}
                    </Flex>
                    <Flex justifyContent={"center"} mt={-8}>
                        <Text fontSize={"1rem"}>{myUser?.anvandarnamn}</Text>
                    </Flex>
                    <Box>
                        <GradeExplanationInfo
                            title={t("sendLettersQualitativeScore")}
                            titleExplanation={getExplanationText(metrics?.generalFeedback)}
                            icon={<FaComment />}
                        />
                        {metrics && <Feedback dto={metrics} t={t} />}
                        {metrics?.numberOfGrammarErrors != null && <GradeExplanationInfo
                            title={t("sendLettersTotalGrammarErrors")}
                            titleExplanation={getExplanationText(metrics?.numberOfGrammarErrors)}
                            icon={<FaSpellCheck />}
                        />}
                        {metrics?.numberOfSpellingErrors != null && <GradeExplanationInfo
                            title={t("sendLettersTotalSpellingErrors")}
                            titleExplanation={getExplanationText(metrics?.numberOfSpellingErrors)}
                            icon={<WarningIcon />}
                        />}
                        {metrics?.smogIndex != null && <GradeExplanationInfo
                            title={t("sendLettersSmogIndex")}
                            titleExplanation={getExplanationText(metrics?.smogIndex)}
                            icon={<BiPencil />}
                        />}
                        {metrics?.readabilityGrade != null && <GradeExplanationInfo
                            title={t("sendLettersReadabilityScore")}
                            titleExplanation={getExplanationText(metrics?.readabilityGrade)}
                            icon={<BiPencil />}
                        />}
                        {(metrics?.totalScore != null) && <>
                            <GradeExplanationInfo
                                title={t("sendLettersQuantitativeScore")}
                                titleExplanation={`${metrics?.totalScore} / ${metrics?.maxScore}`}
                                icon={<FaClipboard />}
                            />
                            {metrics.totalScore/ metrics.maxScore >= 0.8 && <GradeExplanationInfo
                                title={t("pass")}
                                titleExplanation={""}
                                icon={<GoGoal />}
                            />}
                        </>}
                    </Box>
                </ModalBody>
            </Modal>

        </ContainerFullWidthHeightComponent>
    );
};
export function GoBackButton(clickedGoBack: boolean, location: any, setClickedGoBack: any, t: any) {
    return <>
        {clickedGoBack ? (
            location.state?.from === 'settings' ? (
                <Navigate to='/settings' />
            ) : (
                <Navigate to='/inbox' />
            )
        ) : null}
        <Flex alignItems="center">
            <Button variant="ghost" leftIcon={<FaArrowLeft />} justifyContent={"center"} alignItems={"center"} onClick={() => {
                setClickedGoBack(true);
            } }>
                <Text mb={0} display={"block"} size={"16px"} justifyContent={"center"} alignItems={"center"}>{t("back")}</Text>
            </Button>
        </Flex>
    </>;
}

const Feedback = ({ dto, t }: { dto: MetricsDTO, t: (key: string) => string }) => {
    if (!dto.questionFeedback || dto.questionFeedback.length === 0) {
        return null;
    }

    return (
        <div>
            <h3>{t("grade-grade-title")}</h3>
            <ul>
                {dto.questionFeedback.map((example, index) => (
                    <li key={index} style={{ marginBottom: '1em' }}>
                        <strong>{t("grade-question")}:</strong> <em>{example.question}</em>
                        <br />
                        <strong>{t("grade-grade")}:</strong> <em>{example.feedback}</em>
                        <br />
                        <strong>{t("grade-score")}:</strong> {example.score}
                    </li>
                ))}
            </ul>
        </div>
    );
};
