export const enTranslations = {
    "grade-go-back": "Go Back",
    "grade-total-score": "Your score",
    "grade-max-score": "Max score",
    "grade-average-grade": "Average grade per letter",
    "grade-nothing-to-show": "Nothing to show",
    "grade-this-month": "This month",
    settingsTitle: "Settings",
    settingsLanguage: "Language",
    settingsAge: "Age",
    settingsAvatar: "Avatar",
    settingsBio: "Subject",
    settingsUsernamePassword: "Username & Password",
    settingsViewProfile: "View Profile",
    settingsSystemLanguage: "System Language",
    settingsSelectLanguage: "Select Language",
    settingsEnglishUS: "English (US)",
    settingsSwedishSV: "Swedish (SV)",
    settingsClose: "Close",
    settingsGoal: "Goal",
    languageScreenCurrentWritingLanguage: "You are currently writing in:",
    languageScreenSelectLanguagePrompt: "What language would you like to write letters in?",
    languageScreenSearchPlaceholder: "Search and select languages",
    ageScreenEnterBirthday: "Please enter your birthday",
    ageScreenUpdateAgeButton: "Update Age",
    avatarScreenTopType: "Headdress and hair",
    avatarScreenAccessoriesType: "Accessories",
    avatarScreenHatColor: "Hat color",
    avatarScreenHairColor: "Hair color",
    avatarScreenFacialHairType: "Facial hair",
    avatarScreenFacialHairColor: "Facial hair color",
    avatarScreenClotheType: "Clothing",
    avatarScreenClotheColor: "Clothing color",
    avatarScreenEyeType: "Eyes",
    avatarScreenEyebrowType: "Eyebrows",
    avatarScreenMouthType: "Mouth",
    avatarScreenSkinColor: "Skin color",
    avatarScreenBack: "Back",
    avatarScreenSave: "Save",
    avatarScreenCannotSelect: "Cannot select:",
    "save": "Save",
    "create": "Create",
    writeBioPrompt: "What do you want to get better at?",
    writeBioPlaceholder: "Write a subject",
    addAnotherCard: "Add another subject",
    writeBioSubmit: "Submit",
    writeBioSkip: "Skip",
    profileScreenHeading: "Update account information",
    profileScreenUsernameLabel: "Username",
    profileScreenUsernamePlaceholder: "Enter new username",
    profileScreenPasswordLabel: "Password",
    profileScreenPasswordPlaceholder: "Enter new password",
    profileScreenUpdateButton: "Update Profile",
    profileScreenUpdating: "Updating...",
    profileScreenUpdateSuccessTitle: "Profile updated",
    profileScreenUpdateSuccessDescription: "Your username and password have been successfully updated.",
    profileScreenUpdateErrorTitle: "Error",
    profileScreenUpdateErrorDescription: "There was an error updating your profile.",
    homeScreenNoNewMessages: "Postman out for delivery",
    homeScreenLogout: "Logout",
    homeScreenPostmanDelivery: "Letter is being delivered by postman and expected to arrive in 4 hours",
    homeScreenPostmanFromMe: "Your letter is on the way",
    homeScreenPostmanFromOther: "Letter is on the way. Expected to arrive in 4 hours",
    navBarSettings: "Settings",
    navBarGrade: "Grade",
    sendLettersMessageErrorTitle: "Message must contain at least 50 words",
    sendLettersTo: "To",
    sendLettersPlaceholder: "Write your letter...",
    sendLettersGrammarError: "Grammar error",
    sendLettersSpellingError: "Spelling error",
    sendLettersMe: "me",
    sendLettersYou: "you",
    sendLettersNoMessages: "No messages",
    sendLettersGrade: "Grade",
    sendLettersComment: "Comment",
    sendLettersNoMistakes: "No mistakes were found. Good job!",
    sendLettersNoComments: "Nothing to comment.",
    back: "Back",
    sendLettersGrammarSpellingComment: "Comment on Grammar and Spelling Errors",
    sendLettersTotalGrammarErrors: "Total Grammar Errors",
    sendLettersTotalSpellingErrors: "Total Spelling Errors",
    sendLettersSmogIndex: "SMOG Index",
    sendLettersReadabilityScore: "Readability grade",
    sendLettersGrammarErrorsByTotalWords: "Grammar Errors by Total Words",
    sendLettersSpellingErrorsByTotalWords: "Spelling Errors by Total Words",
    sendLettersQuantitativeScore: "Quantitative Score",
    sendLettersQualitativeScore: "Qualitative Score",
    loginTagline: "Everything good comes from love",
    loginUsernameLabel: "Username",
    loginPasswordLabel: "Password",
    loginButton: "Login",
    loginSignupLink: "Sign up",
    signupFillIn: "Fill in",
    signupYour: "your",
    signupLoginDetails: "login details",
    signupUsernameLabel: "Username",
    signupPasswordLabel: "Password",
    signupDateOfBirthLabel: "Date of birth",
    signupNextStep: "Next step",
    signupFillRequirements: "Fill in all the requirements...",
    progressStep: "out of",
    "grade-grade-title": "Grade",
    "grade-question": "Question",
    "grade-grade": "Feedback",
    "grade-score": "Score",
    "grade-noExamplesAvailable": "No grade available",
    goalScreenTitle: "Set Your Learning Goal",
    goalScreenDescription: "Choose a focus area for your learning experience:",
    goalScreenLanguageTitle: "Language (Beginners)",
    goalScreenLanguageDescription: "Focus on learning new languages by writing letters. The letters from the agent are written for beginners. The grading will focus on grammar and spelling errors.",
    goalScreenKnowledgeTitle: "Knowledge-based Questions (Advanced language learners only)",
    goalScreenKnowledgeDescription: "The letters will focus more on asking knowledge-based questions appropriate for your age. The grading will provide feedback on your answers to the questions. The language used in the letters will be more advanced.",
    goalScreenUpdateGoalButton: "Update Goal",
    pass: "Pass",
    writeBioPromptLanguage: "Write something about yourself",
    signUpInvitationCode: "Invitation code",
    signupInvalidUsername: "Username taken",
    writeBioErrorTitle: "Please add a subject",
    writeBioErrorDescription: "You must add a subject before continuing",
    signUpForFreeTrial: "Get 30 days free trial",
    signUpForFreeTrialDescription: "Sign up for a free trial to get started. Invite others for free.",
    signUpForFreeTrialButton: "Sign up",
    signUpAlreadyHaveCode: "Already have a code?",
    signUpInvalidCode: "Invalid code",
    redeemCode: "Redeem code",
    sendLettersMessageWaitForReply: "Let the recipient reply before sending another letter",
};