import { Button, Heading, Radio, RadioGroup, Stack, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { FlexComponent } from '../../components/flex.component';
import { ProgressComponent, Steps } from '../../components/progress-bar.component';
import { Lang } from '../../lang/lang';
import { getUser } from '../auth/hook/auth-query';
import { GoBackButton } from '../letters/send-letters.screen';
import { useUpdateGoal } from './hooks/user-query';

export const GoalScreen = () => {
    const [ goal, setGoal ] = useState<string>("language");
    const { mutate: updateGoal } = useUpdateGoal();
    const [ clickedGoBack, setClickedGoBack ] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const { t } = Lang();
    const user = getUser();
    const [ moveToBio, setMoveToBio ] = useState<boolean>(false);

    // Set initial goal from user data if available
    useEffect(() => {
        if (user?.goal && location?.state?.from !== 'signup') {
            setGoal(user.goal);
        }
    }, [ user ]);

    const handleGoalChange = (value: string) => {
        setGoal(value);
    };

    const handleUpdate = () => {
        if (goal) {
            updateGoal({ goal, userId: user._id }, {
                onSuccess: () => {
                    if(location?.state?.from !== 'signup'){
                        navigate("/settings");
                    }else{
                        setMoveToBio(true);
                    }
                },
            });
        }
    };

    const steps = new Steps(5, 4);

    return (
        <>
            {location?.state?.from !== 'signup' && GoBackButton(clickedGoBack, location, setClickedGoBack, t)}
            {location?.state?.from !== 'settings' && <ProgressComponent steps={steps.steps} atStep={steps.atStep} />}
            {moveToBio && <Navigate to="/write-bio" state={{ from: 'signup', avatar: location?.state?.avatar }} />}
            <Stack justifyContent={"center"} alignItems={"center"}>
                <FlexComponent>
                    <Stack spacing={6} align="center" width="100%" p={10}>
                        <Heading as="h2" size="md">
                            {t("goalScreenTitle")}
                        </Heading>
                        <Text>
                            {t("goalScreenDescription")}
                        </Text>
                        <RadioGroup value={goal} onChange={handleGoalChange}>
                            <Stack direction="column" spacing={4}>
                                <Radio value="language">
                                    <Heading as="h3" size="sm">
                                        {t("goalScreenLanguageTitle")}
                                    </Heading>
                                    <Text fontSize="sm">
                                        {t("goalScreenLanguageDescription")}
                                    </Text>
                                </Radio>
                                <Radio value="knowledge">
                                    <Heading as="h3" size="sm">
                                        {t("goalScreenKnowledgeTitle")}
                                    </Heading>
                                    <Text fontSize="sm">
                                        {t("goalScreenKnowledgeDescription")}
                                    </Text>
                                </Radio>
                            </Stack>
                        </RadioGroup>
                        <Button
                            colorScheme="teal"
                            onClick={handleUpdate}
                        >
                            {t("goalScreenUpdateGoalButton")}
                        </Button>
                    </Stack>
                </FlexComponent>
            </Stack>
        </>
    );
};
